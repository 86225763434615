var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PageHeaderDisplay', {
    attrs: {
      "title": "Add a new site",
      "sub-title": "Use this wizard to add a new asset into Etainabl. If you have multiple assets to add, you can also use the asset import tool.",
      "category": "Assets",
      "category-logo": "fa-buildings",
      "category-route": {
        name: _vm.$route.name
      }
    }
  }), _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "block block block-rounded"
  }, [_c('ul', {
    staticClass: "nav nav-tabs nav-tabs-block nav-justified mb-3",
    attrs: {
      "role": "tablist"
    }
  }, [_c('li', {
    staticClass: "nav-item"
  }, [_c('a', {
    staticClass: "nav-link",
    class: {
      active: _vm.step === 1,
      disabled: _vm.step > 1
    },
    attrs: {
      "href": "#"
    }
  }, [_vm._v("1. Details")])]), _c('li', {
    staticClass: "nav-item"
  }, [_c('a', {
    staticClass: "nav-link",
    class: {
      active: _vm.step === 2,
      disabled: _vm.step < 2
    },
    attrs: {
      "href": "#"
    }
  }, [_vm._v("2. Confirmation")])])]), _c('div', [_c('div', {
    staticClass: "mb-3"
  }, [_c('div', {
    staticClass: "progress",
    staticStyle: {
      "height": "8px"
    },
    attrs: {
      "data-wizard": "progress"
    }
  }, [_c('div', {
    staticClass: "progress-bar progress-bar-striped progress-bar-animated bg-primary",
    style: "width: ".concat(_vm.step * 100 / _vm.totalSteps, "%;"),
    attrs: {
      "role": "progressbar",
      "aria-valuenow": "30",
      "aria-valuemin": "0",
      "aria-valuemax": "100"
    }
  })])]), _c('div', {
    staticClass: "tab-content",
    staticStyle: {
      "min-height": "300px"
    }
  }, [_c('div', {
    staticClass: "tab-pane",
    class: {
      active: _vm.step === 1
    },
    attrs: {
      "role": "tabpanel"
    }
  }, [_vm.error.message ? _c('div', {
    staticClass: "alert alert-danger mb-3",
    attrs: {
      "role": "alert"
    }
  }, [_c('strong', {
    staticClass: "mb-0"
  }, [_vm._v("Something went wrong...")]), _vm._v(" " + _vm._s(_vm.error.message) + " ")]) : _vm._e(), _vm.assetLoading.wizardCheck ? _c('SpinnerLogo', [_vm._v("Checking details...")]) : _vm._e(), _c('SectionTitle', [_vm._v("Basic Information")]), _c('div', {
    staticClass: "row"
  }, [_vm._m(0), _c('div', {
    staticClass: "col-lg-8 col-xl-4"
  }, [_c('FormGroup', {
    attrs: {
      "id": "siteName",
      "label": "Site Name",
      "type": "text",
      "error": _vm.validationErrors['siteName']
    },
    model: {
      value: _vm.form.siteName,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "siteName", $$v);
      },
      expression: "form.siteName"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "assetType",
      "label": "Type",
      "type": "select2",
      "options": _vm.sortedAssetTypes,
      "error": _vm.validationErrors['assetType']
    },
    model: {
      value: _vm.form.assetType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "assetType", $$v);
      },
      expression: "form.assetType"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "entityId",
      "error": _vm.validationErrors['entityId'],
      "options": _vm.entityOptions,
      "type": "select2",
      "label": "Owner/Entity"
    },
    model: {
      value: _vm.form.entityId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "entityId", $$v);
      },
      expression: "form.entityId"
    }
  })], 1)]), _c('SectionTitle', [_vm._v("Address")]), _c('div', {
    staticClass: "row"
  }, [_vm._m(1), _c('div', {
    staticClass: "col-lg-8 col-xl-4"
  }, [_c('AddressForm', {
    attrs: {
      "error": _vm.validationErrors
    },
    model: {
      value: _vm.form.address,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "address", $$v);
      },
      expression: "form.address"
    }
  })], 1)]), _c('SectionTitle', [_vm._v("Floor Area")]), _c('div', {
    staticClass: "row"
  }, [_vm._m(2), _c('div', {
    staticClass: "col-lg-8 col-xl-4"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "floorArea"
    }
  }, [_vm._v("Floor Area")]), _c('div', {
    staticClass: "input-group"
  }, [_c('FormItem', {
    staticClass: "input-group",
    attrs: {
      "id": "floorArea",
      "label": "Floor Area",
      "error": _vm.validationErrors.floorArea,
      "type": "number",
      "min": 1,
      "max": 99999999
    },
    model: {
      value: _vm.form.floorArea,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "floorArea", $$v);
      },
      expression: "form.floorArea"
    }
  }), _c('div', {
    staticClass: "input-group-append"
  }, [_c('FormItem', {
    attrs: {
      "id": "floorAreaUnit",
      "label": "Floor Area Unit",
      "error": _vm.validationErrors.floorAreaUnit,
      "options": [{
        value: 'metric',
        label: 'm2'
      }, {
        value: 'imperial',
        label: 'sq. ft'
      }],
      "type": "select",
      "is-alt": true
    },
    model: {
      value: _vm.form.floorAreaUnit,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "floorAreaUnit", $$v);
      },
      expression: "form.floorAreaUnit"
    }
  })], 1)], 1), _c('div', {
    staticClass: "text-gray-dark font-w600 font-size-sm mt-1"
  }, [_vm._v("Enter \"1\" if you do not know the floor area.")]), _c('FormError', {
    attrs: {
      "id": "floorArea",
      "error": _vm.validationErrors.floorArea
    }
  })], 1)])])], 1), _c('div', {
    staticClass: "tab-pane",
    class: {
      active: _vm.step === 2
    }
  }, [_c('SectionTitle', [_vm._v("Confirmation")]), _vm.loading.submit ? _c('SpinnerLogo', {
    staticClass: "mt-5"
  }, [_vm._v("Please wait while the Asset is created...")]) : _vm.createdAsset._id && !_vm.error.message ? _c('div', {
    staticClass: "text-center"
  }, [_c('p', {
    staticClass: "text-muted font-w600 font-size-h4 mb-3"
  }, [_c('i', {
    staticClass: "fa fa-fw fa-check-circle text-success"
  }), _vm._v(" Asset was created successfully. ")]), _c('router-link', {
    staticClass: "btn btn-primary",
    attrs: {
      "to": {
        name: 'asset-overview',
        params: {
          id: _vm.createdAsset._id
        }
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-fw fa-building mr-1"
  }), _vm._v(" View Asset")])], 1) : _c('div', {
    staticClass: "alert alert-danger mb-3",
    attrs: {
      "role": "alert"
    }
  }, [_c('strong', {
    staticClass: "mb-0"
  }, [_vm._v("Something went wrong...")]), _vm._v(" " + _vm._s(_vm.error.message))])], 1)]), _c('div', {
    staticClass: "block-content block-content-sm block-content-full bg-body-light rounded-bottom"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-6"
  }), _c('div', {
    staticClass: "col-6 text-right"
  }, [_vm.step === 1 ? _c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "submit"
    },
    on: {
      "click": _vm.onClickSubmit
    }
  }, [_c('i', {
    staticClass: "fa fa-check-circle mr-1"
  }), _vm._v(" Create Asset ")]) : _vm._e(), _vm.step === 2 && _vm.createdAsset._id ? _c('button', {
    staticClass: "btn btn-info",
    on: {
      "click": _vm.onCreateAnother
    }
  }, [_c('i', {
    staticClass: "fa fa-fw fa-plus-circle mr-1"
  }), _vm._v("Create another ")]) : _vm._e()])])])])])])], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-4"
  }, [_c('p', {
    staticClass: "text-muted"
  }, [_vm._v("Enter the initial details about this asset.")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-4"
  }, [_c('p', {
    staticClass: "text-muted"
  }, [_vm._v("The physical address of the asset")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-4"
  }, [_c('p', {
    staticClass: "text-muted"
  }, [_c('strong', {
    staticClass: "text-danger"
  }, [_vm._v("Please note")]), _vm._v(" floor area is not strictly required but not setting it correctly will negatively affect reports that use it. ")])]);
}]

export { render, staticRenderFns }