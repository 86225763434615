<template>
  <div>
    <PageHeaderDisplay
      title="Add a new site"
      sub-title="Use this wizard to add a new asset into Etainabl. If you have multiple assets to add, you can also use the asset import tool."
      category="Assets"
      category-logo="fa-buildings"
      :category-route="{ name: $route.name }"
    />

    <div class="content">
      <div class="block block block-rounded">
        <!-- Step Tabs -->
        <ul class="nav nav-tabs nav-tabs-block nav-justified mb-3" role="tablist">
          <li class="nav-item">
            <a class="nav-link" :class="{ active: step === 1, disabled: step > 1 }" href="#">1. Details</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" :class="{ active: step === 2, disabled: step < 2 }" href="#">2. Confirmation</a>
          </li>
        </ul>
        <!-- END Step Tabs -->

        <!-- Form -->
        <div>
          <!-- Wizard Progress Bar -->
          <div class="mb-3">
            <div class="progress" data-wizard="progress" style="height: 8px">
              <div
                class="progress-bar progress-bar-striped progress-bar-animated bg-primary"
                role="progressbar"
                :style="`width: ${(step * 100) / totalSteps}%;`"
                aria-valuenow="30"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>
          <!-- END Wizard Progress Bar -->

          <!-- Steps Content -->
          <div class="tab-content" style="min-height: 300px">
            <!-- Step 1 -->
            <div class="tab-pane" :class="{ active: step === 1 }" role="tabpanel">
              <div v-if="error.message" class="alert alert-danger mb-3" role="alert">
                <strong class="mb-0">Something went wrong...</strong> {{ error.message }}
              </div>

              <SpinnerLogo v-if="assetLoading.wizardCheck">Checking details...</SpinnerLogo>

              <SectionTitle>Basic Information</SectionTitle>

              <div class="row">
                <div class="col-lg-4">
                  <p class="text-muted">Enter the initial details about this asset.</p>
                </div>
                <div class="col-lg-8 col-xl-4">
                  <FormGroup id="siteName" v-model="form.siteName" label="Site Name" type="text" :error="validationErrors['siteName']" />
                  <FormGroup
                    id="assetType"
                    v-model="form.assetType"
                    label="Type"
                    type="select2"
                    :options="sortedAssetTypes"
                    :error="validationErrors['assetType']"
                  />
                  <FormGroup
                    id="entityId"
                    v-model="form.entityId"
                    :error="validationErrors['entityId']"
                    :options="entityOptions"
                    type="select2"
                    label="Owner/Entity"
                  />
                </div>
              </div>

              <SectionTitle>Address</SectionTitle>

              <div class="row">
                <div class="col-lg-4">
                  <p class="text-muted">The physical address of the asset</p>
                </div>
                <div class="col-lg-8 col-xl-4">
                  <AddressForm v-model="form.address" :error="validationErrors" />
                </div>
              </div>

              <SectionTitle>Floor Area</SectionTitle>
              <div class="row">
                <div class="col-lg-4">
                  <p class="text-muted">
                    <strong class="text-danger">Please note</strong> floor area is not strictly required but not setting it correctly will negatively
                    affect reports that use it.
                  </p>
                </div>
                <div class="col-lg-8 col-xl-4">
                  <div class="form-group">
                    <label for="floorArea">Floor Area</label>
                    <div class="input-group">
                      <FormItem
                        id="floorArea"
                        v-model="form.floorArea"
                        label="Floor Area"
                        :error="validationErrors.floorArea"
                        type="number"
                        :min="1"
                        :max="99999999"
                        class="input-group"
                      />
                      <div class="input-group-append">
                        <FormItem
                          id="floorAreaUnit"
                          v-model="form.floorAreaUnit"
                          label="Floor Area Unit"
                          :error="validationErrors.floorAreaUnit"
                          :options="[
                            { value: 'metric', label: 'm2' },
                            { value: 'imperial', label: 'sq. ft' }
                          ]"
                          type="select"
                          :is-alt="true"
                        />
                      </div>
                    </div>
                    <div class="text-gray-dark font-w600 font-size-sm mt-1">Enter "1" if you do not know the floor area.</div>
                    <FormError id="floorArea" :error="validationErrors.floorArea" />
                  </div>
                </div>
              </div>
            </div>
            <!-- END Step 1 -->

            <!-- Step 2 -->
            <div class="tab-pane" :class="{ active: step === 2 }">
              <SectionTitle>Confirmation</SectionTitle>

              <SpinnerLogo v-if="loading.submit" class="mt-5">Please wait while the Asset is created...</SpinnerLogo>

              <div v-else-if="createdAsset._id && !error.message" class="text-center">
                <p class="text-muted font-w600 font-size-h4 mb-3">
                  <i class="fa fa-fw fa-check-circle text-success"></i> Asset was created successfully.
                </p>

                <router-link class="btn btn-primary" :to="{ name: 'asset-overview', params: { id: createdAsset._id } }"
                  ><i class="fa fa-fw fa-building mr-1"></i> View Asset</router-link
                >
              </div>

              <div v-else class="alert alert-danger mb-3" role="alert"><strong class="mb-0">Something went wrong...</strong> {{ error.message }}</div>
            </div>
            <!-- END Step 2 -->
          </div>
          <!-- END Steps Content -->

          <!-- Steps Navigation -->
          <div class="block-content block-content-sm block-content-full bg-body-light rounded-bottom">
            <div class="row">
              <div class="col-6"></div>
              <div class="col-6 text-right">
                <button v-if="step === 1" type="submit" class="btn btn-primary" @click="onClickSubmit">
                  <i class="fa fa-check-circle mr-1"></i> Create Asset
                </button>
                <button v-if="step === 2 && createdAsset._id" class="btn btn-info" @click="onCreateAnother">
                  <i class="fa fa-fw fa-plus-circle mr-1"></i>Create another
                </button>
              </div>
            </div>
          </div>
          <!-- END Steps Navigation -->
        </div>
        <!-- END Form -->
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import AddressForm from '@/components/forms/AddressForm';
import FormGroup from '@/components/FormGroup';
import FormItem from '@/components/FormItem';
import FormError from '@/components/FormError';
import PageHeaderDisplay from '@/components/PageHeaderDisplay';
import SectionTitle from '@/components/base/SectionTitle';
import SpinnerLogo from '@/components/SpinnerLogo';

const initialForm = () => ({
  siteName: '',
  assetType: '',
  entityId: '',
  address: {
    streetAddress: '',
    postCode: '',
    countryCode: 'gb'
  },
  floorArea: 1,
  floorAreaUnit: 'metric'
});

export default {
  name: 'AssetWizard',
  components: {
    AddressForm,
    FormGroup,
    FormItem,
    FormError,
    PageHeaderDisplay,
    SectionTitle,
    SpinnerLogo
  },
  data() {
    return {
      step: 1,
      totalSteps: 2,
      form: initialForm()
    };
  },
  computed: {
    ...mapGetters({
      assetTypes: 'util/assetTypes',
      entities: 'entity/listStructure',
      assetLoading: 'asset/loadingAction',
      loading: 'assetWizard/loading',
      error: 'assetWizard/error',
      validationErrors: 'assetWizard/validationErrors',
      asset: 'assetWizard/asset',
      assetCheck: 'assetWizard/assetCheck',
      createdAsset: 'assetWizard/createdAsset'
    }),
    entityOptions() {
      const extractChildren = (entity, level = 1) =>
        entity.children.reduce(
          (acc, entity) => [...acc, { label: entity.legalName, value: entity._id, level }, ...extractChildren(entity, level + 1)],
          []
        );

      return this.entities.reduce((acc, entity) => {
        return [...acc, { label: entity.legalName, value: entity._id, level: 0 }, ...extractChildren(entity)];
      }, []);
    },
    sortedAssetTypes() {
      const assetTypes = [...this.assetTypes];

      return assetTypes.map(e => ({
        label: e.value,
        value: e.value
      }));
    }
  },
  mounted() {
    this.listEntities();
    this.listAssetTypes();
  },
  methods: {
    ...mapActions({
      listEntities: 'entity/listStructure',
      wizardCheck: 'assetWizard/check',
      submitWizard: 'assetWizard/submit',
      listAssetTypes: 'util/listAssetTypes'
    }),
    ...mapMutations({
      resetWizard: 'assetWizard/RESET_WIZARD'
    }),
    async onClickSubmit() {
      await this.wizardCheck({
        data: {
          ...this.form,
          floorArea: this.form.floorArea || 1
        }
      });

      if (!this.assetCheck) return false;

      this.step = 2;

      this.submitWizard({
        ...this.form,
        floorArea: this.form.floorArea || 1
      });
    },
    onCreateAnother() {
      this.resetWizard();
      this.step = 1;
      this.form = initialForm();
    }
  }
};
</script>
